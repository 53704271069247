import { HStack, Stack, Switch, Tag, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { decodeHTMLEntities, getUserRole } from 'helpers'
import { MoonDarkIcon, MoonLightIcon, SignOutDarkIcon, SignOutLightIcon, UserDarkIcon, UserLightIcon } from 'icons/global'
import { useAuth } from 'oidc-react'

export const SidebarFooter = ({ isExpanded }) => {
	const { colorMode, toggleColorMode } = useColorMode()
	const ellipseColorPassive = useColorModeValue('#34343433', '#898989')
	const {
		userData: { profile },
		userManager,
	} = useAuth()

	const handleSignout = () => {
		userManager.removeUser()
		userManager.signoutRedirect()
		localStorage.clear()
	}

	const role = getUserRole(profile.role)

	const isDarkMode = colorMode === 'dark'

	return (
		<Stack p={4} spacing='3'>
			<HStack justifyContent='space-between'>
				<HStack gap={3} onClick={!isExpanded ? toggleColorMode : undefined} cursor={isExpanded ? 'auto' : 'pointer'}>
					{isDarkMode ? <MoonLightIcon boxSize='8' /> : <MoonDarkIcon boxSize='8' />}
					{isExpanded && <Text fontSize='sm'>Dark Mode</Text>}
				</HStack>
				{isExpanded && <Switch onChange={toggleColorMode} isChecked={isDarkMode} />}
			</HStack>
			<HStack justifyContent='space-between'>
				<HStack gap={3} overflow='hidden'>
					{isDarkMode ? <UserDarkIcon boxSize='8' /> : <UserLightIcon boxSize='8' />}
					{isExpanded && (
						<Text fontSize='sm' width='28' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
							{decodeHTMLEntities(profile.given_name)} {decodeHTMLEntities(profile.family_name)}
						</Text>
					)}
				</HStack>
				{isExpanded && (
					<Tag colorScheme='green' size='sm'>
						{role}
					</Tag>
				)}
			</HStack>
			<HStack
				justifyContent='space-between'
				cursor='pointer'
				onClick={handleSignout}
				_hover={{
					span: {
						backgroundColor: isDarkMode ? 'white' : '#343434',
					},
				}}>
				<HStack gap={3}>
					{isDarkMode ? <SignOutDarkIcon boxSize='8' /> : <SignOutLightIcon boxSize='8' />}
					{isExpanded && <Text fontSize='sm'>Sign Out</Text>}
				</HStack>
				{isExpanded && <Text as='span' height='6px' width='6px' bgColor={ellipseColorPassive} rounded='full' />}
			</HStack>
		</Stack>
	)
}
