import { fetchMethods } from 'enums'
import fetchData from 'helpers/fetchData'

export const getDevices = ({
	hospital,
	appRelease,
	deviceFamilyId,
	firmware,
	partner,
	status,
	pageParam,
	dateFrom,
	dateTo,
	q,
	signal,
	deviceModeId,
}) =>
	fetchData(
		fetchMethods.GET,
		`v1/devices?pageSize=15&currentPage=${pageParam}&search=${q}&filters.deviceFamilyId=${deviceFamilyId}&filters.isOnline=${status}&filters.partnerId=${partner}&filters.buildNumber=${firmware}&filters.appVersion=${appRelease}&filters.date=${dateFrom}&filters.dateTo=${dateTo}&filters.clientId=${hospital}&filters.deviceModeId=${deviceModeId}`,
		{ signal }
	)

export const getModalDevices = (search, signal) =>
	fetchData(fetchMethods.GET, `v1/devices?pageSize=10&search=${search}`, {
		signal,
	})

export const getOsDeploymentClientDevices = ({ deviceMode, deviceFamilyId, clientId, signal }) =>
	fetchData(
		fetchMethods.POST,
		`v1/deployments/device-modes/${deviceMode}/device-families/${deviceFamilyId}/devices?client-id=${clientId}`,
		{
			signal,
		}
	)

export const getDevicesDiagnostics = ({ pageParam, search, signal }) =>
	fetchData(fetchMethods.GET, `v1/device-diagnostics/logs?pageSize=15&currentPage=${pageParam}&search=${search}`, { signal })

export const deleteDeviceDiagnostic = (diagnosticId) =>
	fetchData(fetchMethods.DELETE, `v1/device-diagnostics/logs/${diagnosticId}`)

export const getNSDevices = ({ payload, deviceMode, deviceFamilyId, signal }) =>
	fetchData(fetchMethods.POST, `v1/deployments/device-modes/${deviceMode}/device-families/${deviceFamilyId}/devices`, {
		signal,
		body: JSON.stringify(payload),
	})

export const getDeviceDetails = (serial, deviceId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/details?deviceId=${deviceId}`, { signal })

export const getPopoverDeviceDetails = (serial, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/mdm-details`, { signal })

export const getDeviceConnectedUsb = ({ deviceId, signal }) =>
	fetchData(fetchMethods.GET, `v1/devices/${deviceId}/usb-devices/tree`, { signal })

export const getMlModelDevices = ({ search, companyId, teamId, teamTypeId, mlModelId, version, currentPage, signal }) =>
	fetchData(
		fetchMethods.GET,
		`v1/companies/${companyId}/teams/${teamId}/team-types/${teamTypeId}/ml-models/${mlModelId}/versions/${version}/status?currentPage=${
			currentPage + 1
		}&pageSize=10&search=${search}`,
		{ signal }
	)

export const getDeviceConfigurations = (deviceId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${deviceId}/configurations`, {
		signal,
	})

export const getDeviceTreeview = ({ serial, signal }) =>
	fetchData(fetchMethods.GET, `v1.1/devices/${serial}/assigned-details`, { signal })

export const getDeviceInstalledApps = ({ deviceId, pageSize, pageIndex, search, signal }) =>
	fetchData(
		fetchMethods.GET,
		`v1/devices/${deviceId}/device-apps/installed?page-size=${pageSize}&current-page=${pageIndex}&search=${search}`,
		{ signal }
	)

export const getOngoingConferences = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/conferences/ongoing`, { signal })

export const getPastConferences = (serial, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/conferences/past`, { signal })

export const getReceivedLogs = (serial, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/logs?currentPage=1&pageSize=300`, { signal })

export const getStatusLogs = ({ serial, pageParam, dateFrom, dateTo, isOnline, signal }) =>
	fetchData(
		fetchMethods.GET,
		`v1/devices/${serial}/status-logs?pageSize=10&currentPage=${pageParam}&filters.dateFrom=${dateFrom}&filters.dateTo=${dateTo}&filters.isOnline=${isOnline}`,
		{ signal }
	)

export const getCommandLogs = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/command-logs?pageSize=50`, { signal })

export const getAllowList = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/allowlist/logs`, { signal })

export const getActiveOfflineCommands = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/offline-commands/all`, { signal })

export const getDeviceFamily = (search, signal) => fetchData(fetchMethods.GET, `v1/devices/families?search=${search}`, { signal })

export const getDeviceMode = ({ pageSize, pageIndex, search, signal }) =>
	fetchData(fetchMethods.GET, `v1/devices/modes?pageSize=${pageSize}&currentPage=${pageIndex}&search=${search}`, { signal })

export const getDeviceEditInfo = (solHelloId, signal) => fetchData(fetchMethods.GET, `v1/devices/${solHelloId}`, { signal })

export const getUnasignedDevicesDropdown = ({ pageSize, pageIndex, query, signal }) =>
	fetchData(fetchMethods.GET, `v1/devices/v1.1/unallocated?pageSize=${pageSize}&currentPage=${pageIndex + 1}&search=${query}`, {
		signal,
	})

export const getUnassociatedDevices = (search, signal) =>
	fetchData(fetchMethods.GET, `v1/device-pools/unassociated-devices?search=${search}`, { signal })

export const getCompanionModes = () => fetchData(fetchMethods.GET, `v1/devices/companion/modes`)

export const createDevice = (formObj) => fetchData(fetchMethods.POST, `v1/devices`, { body: JSON.stringify(formObj) })

export const transferToPartner = (formObj) =>
	fetchData(fetchMethods.POST, `v1/devices/re-transfer`, { body: JSON.stringify(formObj) })

export const transferToPartnerBulk = (formObj) =>
	fetchData(fetchMethods.POST, `v1/devices/transfer`, { body: JSON.stringify(formObj) })

export const sendCommand = ({ isOnline, deviceId, ...rest }) => {
	const url = `v1/devices/${deviceId}/${isOnline ? 'command' : 'offline-commands'}`
	return fetchData(fetchMethods.POST, url, { body: JSON.stringify({ deviceId, ...rest }) })
}

export const editDeviceWithId = (formObj, deviceId) =>
	fetchData(fetchMethods.PUT, `v1/devices/${deviceId}`, { body: JSON.stringify(formObj) })

export const deleteDeviceWithId = (deviceId) => fetchData(fetchMethods.DELETE, `v1/devices/${deviceId}`)

export const removeFromPartner = (deviceId) => fetchData(fetchMethods.DELETE, `v1/devices/device-of-partner/${deviceId}`)

export const notifyDeviceOSDeployment = (payload) =>
	fetchData(fetchMethods.POST, 'v1/devices/notify/os-update', { body: JSON.stringify(payload) })
